import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listFormation } from '../../slices/formation.slice';
import { createCandidature, incCandVisit } from '../../slices/candidature.slice';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge'

const niv_requis_options = [
    { label: 'sans-bac', value: null },
    { label: 'Bac', value: 'bac' },
    { label: 'Bac +2', value: 'bac+2' },
    { label: 'Bac +3', value: 'bac+3' },
    { label: 'Bac +4', value: 'bac+4' },
    { label: 'Bac +5', value: 'bac+5' },
]

const Candidater = () => {


    const validationSchema = Yup.object().shape({
        nom: Yup.string().required('Ce champ est obligatoire !'),
        prenom: Yup.string().required('Ce champ est obligatoire !'),
        email: Yup.string().email('email non valide !').required('Ce champ est obligatoire !'),
        tel: Yup.string().required('Ce champ est obligatoire !').test(
            'is-valid',
            'Num tél invalide : 8 chiffres (TN) ou 10 chiffres (FR) , sans code pays (+216) ou (+33)',
            value => (value + "").match(/^[0-9]{8}$/) || (value + "").match(/^[0-9]{10}$/),
        ),
        adresse: Yup.string().required('Ce champ est obligatoire !'),
        // ddn: Yup.string().required('Ce champ est obligatoire !'),
        niv_educ: Yup.string().required('Ce champ est obligatoire !'),
        formationId: Yup.string().required('Ce champ est obligatoire !'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, setValue, formState } = useForm(formOptions);
    const { errors } = formState;

    const { loading, error } = useSelector(state => state.formation_store);
    const { loading: loadingCand, error: errorCand } = useSelector(state => state.candidature_store);
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_formation, set_lst_formation] = useState([])
    const [cand_success, set_cand_success] = useState(false)
    const [incv_done, set_incv_done] = useState(false)

    const load_data = () => {
        dispatch(listFormation()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_formation(res?.rows)
            }
        })
    }

    const updateIncV = () => {
        if (!incv_done)
            dispatch(incCandVisit()).unwrap().then(res => {
                set_incv_done(true)
            })
    }

    const onSubmitCandidature = (data) => {
        let formation = lst_formation.find(el => el?.id == data?.formationId)
        dispatch(createCandidature({ ...data, nom_formation: formation?.libelle })).unwrap().then(res => {
            if (res?.done) {
                set_cand_success(true)
            }
        })
    }

    useEffect(() => {
        set_cand_success(false)
        load_data()
        updateIncV()
    }, [])


    return (
        <div className="container py-5">
            {
                !cand_success ?

                    <div className='row m-0'>

                        <div className="col-md-6 text-center">
                            <h1 className='txt-primary fnt-w9'>Candidatez en ligne</h1>
                            <h5 className='txt-secondary fnt-w9'>LE CAMPUS QUI VA TE DONNER DES AILES</h5>
                            <img src={'/img/logo/logo.png'} alt="" width='70%' />
                        </div>
                        <div className="col-md-6">
                            <form onSubmit={handleSubmit(onSubmitCandidature)} className="row m-0 formation-resume-box-r py-5">

                                <div className="col-md-12 form-group col-12 mb-3">
                                    <label className="fnt-w9 fnt-sm">Nom</label>
                                    <input type="text" {...register("nom")} className={`form-control form-control-sm no-border-rad`} />
                                    <p className="text-danger">{errors.nom?.message}</p>
                                </div>
                                <div className="col-md-12 form-group col-12 mb-3">
                                    <label className="fnt-w9 fnt-sm">Prénom</label>
                                    <input type="text" {...register("prenom")} className={`form-control form-control-sm no-border-rad`} />
                                    <p className="text-danger">{errors.prenom?.message}</p>
                                </div>
                                <div className="col-md-12 form-group col-12 mb-3">
                                    <label className="fnt-w9 fnt-sm">E-mail</label>
                                    <input type="text" {...register("email")} className={`form-control form-control-sm no-border-rad`} />
                                    <p className="text-danger">{errors.email?.message}</p>
                                </div>
                                <div className="col-md-12 form-group col-12 mb-3">
                                    <label className="fnt-w9 fnt-sm">Téléphone</label>
                                    <input type="text" {...register("tel")} className={`form-control form-control-sm no-border-rad`} />
                                    <p className="text-danger">{errors.tel?.message}</p>
                                </div>
                                {/* <div className="col-md-12 form-group col-12 mb-3">
                                    <label className="fnt-w9 fnt-sm">Date de naissance</label>
                                    <input type="date" {...register("ddn")} className={`form-control form-control-sm no-border-rad`} />
                                    <p className="text-danger">{errors.ddn?.message}</p>
                                </div> */}
                                <div className="col-md-12 form-group col-12 mb-3">
                                    <label className="fnt-w9 fnt-sm">Adresse</label>
                                    <textarea rows={3} type="text" {...register("adresse")} className={`form-control form-control-sm no-border-rad`} />
                                    <p className="text-danger">{errors.adresse?.message}</p>
                                </div>



                                <div className="form-group col-md-12 mb-3">
                                    <label className="fnt-w9 fnt-sm">Niveau d'éducation</label>
                                    <Form.Select {...register("niv_educ")} className={`form-control form-control-sm no-border-rad`}>
                                        <option value="" disabled selected>Choisissez une option</option>
                                        {
                                            Array.isArray(niv_requis_options) && niv_requis_options.map(el => <option value={el?.value} key={`sel-niveduc-${el?.value}`}>{el?.label}</option>)
                                        }
                                    </Form.Select>
                                    <p className="text-danger">{errors.niv_educ?.message}</p>
                                </div>
                                <div className="form-group col-md-12 mb-3">
                                    <label className="fnt-w9 fnt-sm">Formation souhaitée</label>
                                    <Form.Select {...register("formationId")} className={`form-control form-control-sm no-border-rad`}>
                                        <option value="" disabled selected>Choisissez une option</option>
                                        {
                                            Array.isArray(lst_formation) && lst_formation.map(el => <option value={el?.id}>{el?.libelle}</option>)
                                        }
                                    </Form.Select>
                                    <p className="text-danger">{errors.formationId?.message}</p>
                                </div>

                                <div className="col-md-12  py-3 text-end" >
                                    {loading ?
                                        <div className="col-md-12 p-4 text-ctr">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        :
                                        <button type="submit" className="btn app-btn-primary px-4" >ENVOYER <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                                    }
                                </div>

                            </form>
                        </div>

                    </div>
                    :
                    <div className="row m-0 p-3">
                        <div className="col-md-12 text-center formation-resume-box-r p-4">
                            <img src="/img/goal.svg" width="30%" alt="" />
                            <h1 className='txt-primary fnt-w9'>Candidature envoyée avec succès</h1>
                            <h5 className='txt-secondary fnt-w9'>Vous recevrez un e-mail lors du traitement de votre demande</h5>
                            <Link to={'/formation'}>
                                <button className='btn app-btn-primary px-3 py-2 my-3'>EXPLORER PLUS DE FORMATION</button>
                            </Link>
                        </div>
                    </div>
            }
        </div>
    );
};

export default Candidater;