import React, { useState, useEffect } from 'react';
// import logo from '/img/logo_.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, NavLink, Routes, Route, Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

const Header = ({ user_routes, loggedUser, onLogOut, isOpen, setIsopen, organisme }) => {
    const navigate = useNavigate();
    const onSubLink = (slug) => {
        if (Link)
            navigate(`/page/${slug}`)
    }

    const renderMenuItem = (item) => {
        if (item?.type == 'link' || item?.type == 'page') {
            return <NavLink to={item?.target} className='nav-link' key={`nav-${item?.label}`}>{item?.label}</NavLink>
        } else if (item?.type == 'dropdown') {
            return <NavDropdown renderMenuOnMount={true} title={`${item?.label}`} id="nav-dropdown" key={`nav-${item?.label}`} onSelect={onSubLink}>
                {
                    Array.isArray(item?.sub_links) && item?.sub_links.map(l => <NavDropdown.Item eventKey={l?.slug}>{l?.titre}</NavDropdown.Item>)
                }
            </NavDropdown>
        } else if (item?.type == 'button') {
            return <NavLink to={item?.target} className='nav-link py-2' > <button className='btn btn-danger btn-sm fnt-w9 no-border-rad btn-header px-3'>{item?.label}</button></NavLink>
        }
    }


    return (
        <div className="header bg-white">
            <div className="row m-0 pt-2">
                <div className="col-md-4"></div>
                <div className="col-md-4 text-center">
                    <Link to={`/`}>
                        <img
                            src={'/img/logo/logo.png'}
                            width="auto"
                            height="72"
                            className="d-inline-block align-top"
                            alt="Intact"
                        />
                    </Link>
                </div>
                <div className="col-md-4"></div>

            </div>
            <Navbar bg="white" expand="lg" >
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto ms-auto" id='h-menu'>
                            {
                                Array.isArray(user_routes) && user_routes.map(el => renderMenuItem(el))
                                // Array.isArray(user_routes) && user_routes.map(el => (
                                //     el?.type == 'link' ?
                                //         <NavLink to={el?.path} className='nav-link' key={`nav-${el?.label}`}>{el?.label}</NavLink>
                                //         :
                                //         <NavDropdown renderMenuOnMount={true} title={`${el?.label}`} id="nav-dropdown" key={`nav-${el?.label}`} onSelect={onSubLink}>
                                //             {
                                //                 Array.isArray(el?.sub_links) && el?.sub_links.map(l => <NavDropdown.Item eventKey={l?.slug}>{l?.titre}</NavDropdown.Item>)
                                //             }
                                //         </NavDropdown>
                                // )
                                // )
                            }

                            {/* <NavLink to={'candidater'} className='nav-link py-2' > <button className='btn btn-danger btn-sm fnt-w9 no-border-rad btn-header px-3'>CANDIDATER</button></NavLink> */}


                            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
                            </NavDropdown> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default Header;