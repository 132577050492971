import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listFormation } from '../../slices/formation.slice';

import ic_diplome_etat from '../../assets/img/ic_diplome_etat.png'
import ic_france_comp from '../../assets/img/ic_france_comp.png'

import Badge from 'react-bootstrap/Badge'

const filter_type = [
    { label: 'Tous', value: null },
    { label: 'Initial', value: 'is_initial' },
    { label: 'Alternance', value: 'is_alternance' },
]

const filter_bac = [
    { label: 'Tous', value: null },
    { label: 'Bac', value: 'bac' },
    { label: 'Bac +2', value: 'bac+2' },
    { label: 'Bac +3', value: 'bac+3' },
    { label: 'Bac +4', value: 'bac+4' },
]

const FormationList = () => {

    const { loading, error } = useSelector(state => state.formation_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [sel_filter_type, set_sel_filter_type] = useState(null)
    const [sel_filter_bac, set_sel_filter_bac] = useState(null)

    const doSearch = value => {
    }

    const load_data = () => {
        let filter = {}
        if (sel_filter_type) {
            filter[sel_filter_type] = true
        }
        if (sel_filter_bac) {
            filter['niv_requis'] = sel_filter_bac
        }
        dispatch(listFormation(filter)).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_data(res?.rows)
            }
        })
    }

    useEffect(() => {
        load_data()
    }, [sel_filter_type, sel_filter_bac])

    useEffect(() => {
        load_data()
    }, [])


    return (
        <div className='row m-0 my-3 px-5'>

            <div className="col-md-12">
                <h1 className='fnt-w9 txt-primary ps-2'>Découvrez nos formations</h1>
            </div>
            <div className="col-md-12 my-3">
                <div className="row m-0 p-3 text-center formation-filter-container">
                    <div className="col-auto pt-1">
                        <FontAwesomeIcon icon={['fas', 'filter']} />
                    </div>

                    <div className="col-md-auto dvdr-l-gray">
                        {
                            Array.isArray(filter_type) && filter_type.map(el => <button key={`btn-flt-${el?.value}`} className={`btn btn-sm btn-formation-filter me-2 ${sel_filter_type == el?.value && 'btn-formation-filter-active'}`} onClick={e => set_sel_filter_type(el?.value)}>{el?.label}</button>)
                        }
                    </div>
                    <div className="col-md-auto dvdr-l-gray">
                        {
                            Array.isArray(filter_bac) && filter_bac.map(el => <button key={`btn-flt-${el?.value}`} className={`btn btn-sm btn-formation-filter me-2 ${sel_filter_bac == el?.value && 'btn-formation-filter-active'}`} onClick={e => set_sel_filter_bac(el?.value)}>{el?.label}</button>)
                        }
                    </div>


                </div>
            </div>

            <div className="col-md-12">
                <div className="row m-0 ">
                    {
                        Array.isArray(lst_data) && lst_data.map(el =>
                            <FormationItem item={el} key={`itm-form-${el?.id}`} />
                        )
                    }
                </div>
            </div>

        </div>
    );
};

const FormationItem = ({ item }) => {
    return (
        <div className="col-md-3 p-2">
            <Link to={`${item?.slug}`}>
                <div className="row m-0 formation-list-container px-2 py-4">
                    <h4 className='fnt-w9 txt-primary' style={{ height: 90, overflow: 'hidden' }}>{item?.libelle}</h4>
                    <div className="col-md-12 pb-1">
                        <b className='fnt-w9'>{item?.duree || '-'}</b>
                    </div>
                    <div className="col-md-12 pb-1" style={{ height: 28, overflow: 'hidden' }}>
                        {item?.is_alternance && <Badge bg='warning txt-primary no-border-rad me-2'>Alternance</Badge>}
                        {item?.is_initial && <Badge bg='warning txt-primary no-border-rad me-2'>Initiale</Badge>}
                    </div>
                    <p style={{ height: 70, overflow: 'hidden' }}>{item?.descriptif_court}</p>
                    <div className="col-md-12 text-center pb-2 pt-2" style={{ height: 56 }}>
                        {item?.is_diplome_etat && <img className='me-2' src={ic_diplome_etat} height={46} alt="" />}
                        {item?.is_france_competence && <img src={ic_france_comp} height={46} alt="" />}
                    </div>

                    <button className='btn formation-btn-secondary py-2 w-100 mt-3'>VOIR LA FORMATION <FontAwesomeIcon icon={['fas', 'caret-right']} /></button>
                </div>
            </Link>
        </div>
    )
}
export default FormationList;