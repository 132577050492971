import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class candidatureService {

    create(data) {
        let token = storage.getToken();
        let organisme_token = window?.PUBLIC_TOKEN;
        return axios.post(`${api_url}/public/candidature/candidater`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    incCandVisit(data) {
        let token = storage.getToken();
        let organisme_token = window?.PUBLIC_TOKEN;
        return axios.get(`${api_url}/public/organisme/inc-cvisit`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

}

export default new candidatureService()