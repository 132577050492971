import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import hero_bottom_shape from '../../../assets/img/hero_bottom_shape.svg'
import hero_default_img from '../../../assets/img/hero_default_img1.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api_file_url } from '../../../config/Params.constant';


const defaultData = {
    title: '<h1><strong class="ql-size-huge" style="color: rgb(250, 176, 41);"> TITRE, </strong> </h1> <h2><span class="ql-size-huge" style="color: rgb(255, 255, 255);"> SLOGANT OU SOUS-TITRE </span> </h2>',
    btn_text: 'LINK',
    btn_link: '#',
    img: hero_default_img,
    style_bg: 'ly-bg-primary',
    style_btn: 'ly-btn-secondary',
    style_img: '',
}

const style_bg_var = ['ly-hero-grad-bg1', 'ly-hero-grad-bg2', 'ly-hero-grad-bg3', 'ly-hero-grad-bg4', 'ly-bg-primary', 'ly-bg-secondary', 'ly-bg-primary-grad', 'ly-bg-secondary-grad', 'ly-bg-white', 'ly-bg-dark', 'ly-bg-gray']
const style_btn_var = ['ly-btn-primary', 'ly-btn-secondary', 'ly-btn-primary ly-bg-primary-grad', 'ly-btn-secondary ly-bg-secondary-grad', 'ly-btn-dark']


const LyHeroBshape = ({ section, loading }) => {

    return (
        <div className={`row m-0 px-5 py-0 position-relative ly-hero-container ${section?.meta_data?.style_bg}`}>

            <div className="col-md-6 d-flex pb-5 mb-5 ly-z-99 ">
                <div className='align-self-center'>
                    {parse(String(section?.meta_data?.title).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}

                    <a target='_blank' href={`${section?.meta_data?.btn_link ? section?.meta_data?.btn_link : '#'}`} className={`btn px-5 py-2 ${section?.meta_data?.style_btn}`}>{section?.meta_data?.btn_text}</a>
                </div>
            </div>


            <div className="col-md-6 text-center position-relative pt-1 mb-5 ly-z-99">
                <img src={section?.meta_data?.img ? `${api_file_url}${section?.meta_data?.img}` : defaultData.img} width='90%' className='' alt="" />
            </div>

            <div className="col-md-12 p-0 m-0 position-absolute bottom-0 start-0">
                <img src={hero_bottom_shape} width='100%' alt="" />
            </div>
        </div>
    );
};

export default LyHeroBshape;