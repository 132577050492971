import React, { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

const CookiesBox = (props) => {
    const [cookie_, set_cookie_] = useState(false)

    const load_cookies = () => {
        if (localStorage) {
            let tmp = JSON.parse(localStorage.getItem('educ-cookies'));
            set_cookie_(tmp)
        }
    }

    const onAccept = () => {
        if (localStorage) {
            localStorage.setItem('educ-cookies', true);
            load_cookies()
        }
    }


    useEffect(() => {
        load_cookies()
    }, [])
    return (
        <Offcanvas show={!cookie_}  {...props} placement={'bottom'} className="w-100" style={{ height: 'fit-content' }}>

            <Offcanvas.Body className='py-3'>
                <div className="row m-0">
                    <div className="col-md-10">
                        <p className='m-0'>Nous utilisions des cookies pour vous garantir la meilleur expérience sur notre site web . Si vous continuez à utiliser ce site, nous supposerons que vous êtes satisfait.</p>
                    </div>
                    <div className="col-md-2">
                        <button className='btn btn-sm btn-success me-1' onClick={onAccept}>Accepter</button>
                        <button className='btn btn-sm btn-danger' onClick={e => set_cookie_(true)}>Refuser</button>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CookiesBox;