import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import YouTube from 'react-youtube';
import parse from 'html-react-parser';

const defaultData = {
    videoId: ''
}
const LyVideo = ({ section }) => {


    return (
        <div className={`row m-0 p-3 ${section?.meta_data?.style_bg}`}>

            <div className="col-md-6 text-center">

                <YouTube videoId={section?.meta_data?.videoId} />

            </div>

            <div className='col-md-6'>
                {section?.meta_data?.content ? parse(String(section?.meta_data?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '') : parse(String(defaultData?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
            </div>




        </div>
    );
};



export default LyVideo;