import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewFormation } from '../../slices/formation.slice';
import parse from 'html-react-parser';

import ic_diplome_etat from '../../assets/img/ic_diplome_etat.png'
import ic_france_comp from '../../assets/img/ic_france_comp.png'
import bg_objectif from '../../assets/img/bg_objectif.svg'

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import { api_file_url } from '../../config/Params.constant';

const FormationDetails = () => {
    const { loading, error } = useSelector(state => state.formation_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [formation, set_formation] = useState([])

    const load_data = () => {
        if (params?.slug)
            dispatch(viewFormation(params?.slug)).unwrap().then(res => {
                if (res?.done && res?.entity) {
                    set_formation(res?.entity)
                }
            })
    }

    useEffect(() => {
        load_data()
    }, [params?.slug])

    return (
        <div className='row m-0 formation-container py-3 pb-0'>

            {/* ----------------------------------------------Section Resume----------------------------------------------------- */}
            <div className="col-md-12">
                <div className="row m-0">

                    <div className="col-md-6">
                        <h1 className='formation-title'>{formation?.libelle}</h1>

                        <h3 className='formation-sub-title'>{formation?.descriptif_court}</h3>

                        <p className='formation-description-dark'>{formation?.descriptif_long}</p>

                        <div className="row m-0 ">
                            <div className="col-md-6 ps-0">
                                <a className='btn formation-btn-primary py-3 w-100' href="#box-programme">VOIR LE PROGRAMME</a>
                            </div>
                            <div className="col-md-6 pe-0">
                                <a target='_blank' rel="noreferrer" href={formation?.fiche_formation ? `${api_file_url}${formation?.fiche_formation}` : '#'} className='btn formation-btn-secondary py-3 w-100'>TÉLÉCHARGER LA FICHE</a>
                            </div>
                        </div>

                    </div>


                    <div className="col-md-6">
                        <div className="row m-0 formation-resume-box-r p-3">
                            <div className="col-md-6 text-center">
                                <img src={ic_diplome_etat} alt="" />
                            </div>

                            <div className="col-md-6 text-center">
                                <img src={ic_france_comp} alt="" />
                            </div>

                            <div className="col-md-12 py-3"></div>

                            <div className="col-md-12">
                                <h4 className='formation-mini-title'>CAMPUS</h4>
                                <p>{formation?.campus}</p>
                            </div>

                            <div className="col-md-12">
                                <h4 className='formation-mini-title'>DURÉE</h4>
                                <p>{formation?.duree}</p>
                            </div>


                            <div className="col-md-12">
                                <h4 className='formation-mini-title'>CERTIFICATION</h4>
                                <p>{formation?.certification}</p>
                            </div>


                            <div className="col-md-12">
                                <h4 className='formation-mini-title'>PRÉ-REQUIS</h4>
                                <p>{formation?.pre_requis}</p>
                            </div>


                            <div className="col-md-12">
                                <h4 className='formation-mini-title'>PROCHAINE SESSION</h4>
                                <p>{formation?.prochaine_session}</p>
                            </div>

                            <div className="col-md-12">
                                <h4 className='formation-mini-title'>TARIF</h4>
                                <p>Nos tarifs et mode de financement sont consultable sur <a href='/tarifs' className='text-danger'>cette page.</a></p>
                            </div>
                            <div className="col-md-12">
                                <Link to={'/candidater'}>
                                    <button className='btn formation-btn-primary py-3 w-100'>CANDIDATER</button>
                                </Link>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


            {/* ----------------------------------------------Section Objectif----------------------------------------------------- */}

            <div className="col-md-12 p-0 py-5">
                <div className="row m-0"></div>
                <div className="row m-0 px-5 formation-objectif-container">

                    <div className="col-md-4 text-center">
                        <img src={bg_objectif} width="100%" alt="" />
                    </div>
                    <div className="col-md-8 pt-3">
                        <h2 className='fnt-w9 txt-light mb-4'><b>Objectif de la formation</b></h2>
                        {formation && parse(String(formation?.objectif).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}

                        <div className="col-md-12 text-start">
                            <Link to={'/candidater'}>
                                <button className='btn formation-btn-secondary py-3 px-5 w-auto'>CANDIDATER</button>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="row m-0"></div>
            </div>

            {/* ----------------------------------------------Section Savoir----------------------------------------------------- */}


            <div className='row m-0 formation-container py-3' id='box-programme'>
                <h2 className='fnt-w9 txt-primary my-4'>Tout savoir sur la formation</h2>
                <Tab.Container id="left-tabs-example" defaultActiveKey="programme">
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column formation-v-nav pt-0">
                                <Nav.Item><Nav.Link eventKey="programme">Programme</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="pre_requis">Pré-requis</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="modalite_inscription">Modalités d'inscription</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="admission">Admission</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="publics_concernes">Publics concernés</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="sortie_formation">A la sortie de formation</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="intervenant">Intervenants</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="accessibilite">Accessibilité</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="debouches">Débouchés</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="poursuite_etudes">Poursuite d'études</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="contacts">Contacts utiles</Nav.Link></Nav.Item>

                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>

                                <Tab.Pane eventKey="programme">
                                    <FormationProgrammeBox formation={formation} loading={loading} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="pre_requis">
                                    <FormationInfoBox target_fields={[{ title: 'Pré-requis', field: 'descriptif_pre_requis' }]} formation={formation} loading={loading} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="modalite_inscription">
                                    <FormationInfoBox target_fields={[{ title: 'Alternance', field: 'descriptif_alternance' }, { title: 'Initiale', field: 'descriptif_initial' }]} formation={formation} loading={loading} />
                                </Tab.Pane>

                                <Tab.Pane eventKey="admission">
                                    <FormationInfoBox target_fields={[{ title: 'Votre admission', field: 'admission' }]} formation={formation} loading={loading} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="publics_concernes">
                                    <FormationInfoBox target_fields={[{ title: 'Publics concernés', field: 'publics_concernes' }]} formation={formation} loading={loading} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="sortie_formation">
                                    <FormationInfoBox target_fields={[{ title: 'A la sortie de la formation', field: 'sortie_formation' }]} formation={formation} loading={loading} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="intervenant">
                                    <FormationInfoBox target_fields={[{ title: 'Intervenant', field: 'intervenant' }]} formation={formation} loading={loading} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="accessibilite">
                                    <FormationInfoBox target_fields={[{ title: 'Accessibilités', field: 'accessibilite' }]} formation={formation} loading={loading} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="debouches">
                                    <FormationLieeBox formation={formation} type={'debouche'} loading={loading} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="poursuite_etudes">
                                    <FormationLieeBox formation={formation} type={'poursuite'} loading={loading} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="contacts">
                                    <FormationInfoBox target_fields={[{ title: 'Contacts utiles', field: 'contact' }]} formation={formation} loading={loading} />
                                </Tab.Pane>

                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>

            </div>


            {/* ----------------------------------------------s'inscrire formation----------------------------------------------------- */}
            <div className="col-md-12 p-0 py-5 text-center bg-white">
                <h3 className='fnt-w9 txt-dark'>Cette formation est faite pour vous ?</h3>
                <Link to={'/candidater'}>
                    <button className='btn formation-btn-secondary py-3 px-5 w-auto me-3'>POSTULER À LA FORMATION</button>
                </Link>
                <a target='_blank' rel="noreferrer" href={formation?.fiche_formation ? `${api_file_url}${formation?.fiche_formation}` : '#'} className='btn formation-btn-primary py-3 px-5 w-auto'>TÉLÉCHARGER LA FICHE</a>
            </div>
        </div>
    );
};




const FormationInfoBox = ({ formation, target_fields }) => {
    return (
        <div className='row m-0 formation-container p-3'>
            {
                Array.isArray(target_fields) && target_fields.map(el =>
                    <div className="col-md-12 formation-info-box">
                        <h4 className='formation-info-box-title'>{el?.title}</h4>
                        <div className="row m-0 p-2">
                            {formation && parse(String(formation[el?.field]).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
                        </div>
                    </div>
                )
            }

        </div>
    )
}



const FormationProgrammeBox = ({ formation }) => {
    return (
        <div className='row m-0 formation-container py-3'>
            <div className="col-md-12 formation-info-box mb-5">
                <h4 className='formation-info-box-title'>{'Programme de la formation'}</h4>

                <div className="col-md-12 py-2">
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Matière</th>
                                <th>Coef</th>
                                <th>Durée</th>
                                <th>Forme</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(formation?.formation_programmes) && formation?.formation_programmes.map(el =>
                                    <tr>
                                        <td>{el?.libelle}</td>
                                        <td>{el?.coef}</td>
                                        <td>{el?.duree}</td>
                                        <td>{el?.forme}</td>

                                    </tr>
                                )
                            }

                        </tbody>
                    </Table>
                </div>
            </div>

            <div className="col-md-12 formation-info-box mb-5">
                <h4 className='formation-info-box-title'>{'Blocs de compétences'}</h4>
                {formation && parse(String(formation?.competences_attestees).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
            </div>

            <div className="col-md-12 formation-info-box mb-5">
                <h4 className='formation-info-box-title'>{'Modalités pédagogiques'}</h4>
                {formation && parse(String(formation?.modalite_pedagogiques).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
            </div>

            <div className="col-md-12 formation-info-box mb-5">
                <h4 className='formation-info-box-title'>{'Modalités d\'évaluation'}</h4>
                {formation && parse(String(formation?.modalite_evaluation).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
            </div>
        </div>
    )
}



const FormationLieeBox = ({ formation, type }) => {
    return (
        <div className='row m-0 formation-container py-3'>
            <div className="col-md-12 formation-info-box mb-5">
                <h4 className='formation-info-box-title'>{type == 'debouche' ? 'Débouchés de la formation' : 'Poursuite d\'études de la formation'}</h4>

                <div className="col-md-12 py-2">

                    <div className="row m-0 py-2">
                        {
                            Array.isArray(formation?.formation_liees) && formation?.formation_liees.filter(el => el?.type == type).map(el =>
                                <div className='col-md-4'>
                                    <div className="row m-0 formation-list-container px-2 py-4">
                                        <h4 className='fnt-w9 txt-primary' style={{ minHeight: 60, overflow: 'hidden' }}>{el?.libelle}</h4>

                                        <div className="col-md-6">
                                            {
                                                el?.duree && <b>{el?.duree}</b>
                                            }
                                        </div>
                                        <div className="col-md-6">
                                            <a href={el?.lien_formation} target='_blank' rel='noreferrer' className='btn formation-btn-secondary py-1 w-100 fnt-w5'><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></a>
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                    </div>

                </div>

            </div>
        </div>
    )
}

export default FormationDetails;