import React from 'react';

const OfflinePage = () => {
    return (
        <div className='row m-0 p-5'>
            <div className="col-md-12 p-5 bg-white shadow text-center">
                <img src="./img/offline_bg.svg" width='30%' alt="" />
                <h1 className='mt-3 fnt-w5'>site en cours de construction</h1>
            </div>
        </div>
    );
};

export default OfflinePage;