import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewPage } from '../../slices/page.slice';
import LayoutImageTextHoriz from './templates/LyImageTextHoriz';
import LayoutImageTextVert from './templates/LyImageTextVert';
import Layout3ImageTextBox from './templates/Ly3ImageTextBox';
import ContactWidget from './widgets/ContactWidget';
import FormationWidget from './widgets/FormationWidget';
import FaqWidget from './widgets/FaqWidget';
import LyHeroBshape from './templates/LyHeroBshape';
import LayoutOnlyText from './templates/LyOnlyText';
import LyTable from './templates/LyTable';
import LyVideo from './templates/LyVideo';

const PageView = () => {

    const { loading, error } = useSelector(state => state.page_store);
    const params = useParams();
    const dispatch = useDispatch();

    const [page, set_page] = useState(null)
    const load_data = () => {
        if (params?.slug)
            dispatch(viewPage(params?.slug)).unwrap().then(res => {
                if (res?.done && res?.entity) {
                    set_page(res?.entity)
                }
            })
    }

    useEffect(() => {
        load_data()
    }, [params?.slug])

    const renderSection = (item) => {
        switch (item?.layout) {

            case "LayoutImageTextHorizLeft": return <LayoutImageTextHoriz section={item} key={`sect-data-${item?.id}`} />;
            case "LayoutImageTextHorizRight": return <LayoutImageTextHoriz section={item} key={`sect-data-${item?.id}`} />;
            case "LayoutImageTextVert": return <LayoutImageTextVert section={item} key={`sect-data-${item?.id}`} />;
            case "LayoutOnlyText": return <LayoutOnlyText section={item} key={`sect-data-${item?.id}`} />;
            case "LayoutTable": return <LyTable section={item} key={`sect-data-${item?.id}`} />;
            case "LayoutVideo": return <LyVideo section={item} key={`sect-data-${item?.id}`} />;
            case "Layout3ImageTextBox": return <Layout3ImageTextBox section={item} key={`sect-data-${item?.id}`} />;
            case "LayoutHeroBShape": return <LyHeroBshape section={item} key={`sect-data-${item?.id}`} />;
            case "WidgetContact": return <ContactWidget section={item} key={`sect-data-${item?.id}`} loading={loading} />;
            case "WidgetFormation": return <FormationWidget section={item} key={`sect-data-${item?.id}`} loading={loading} />;
            case "WidgetFaq": return <FaqWidget section={item} key={`sect-data-${item?.id}`} loading={loading} />;


            default: return <h1>No layout match</h1>
        }
    }


    return (
        <div className='row m-0 p-0'>
            {
                Array.isArray(page?.page_sections) && page?.page_sections.map(el => renderSection(el))
            }
        </div>
    );
};

export default PageView;