import React from 'react';
import parse from 'html-react-parser';
import defaultImage from '../../../assets/img/ic_photo.png'

const defaultData = {
    title: 'Section Title',
    boxes: [
        {
            title: 'Box Title',
            img: defaultImage,
            content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'
        },
        {
            title: 'Box Title',
            img: defaultImage,
            content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'
        },
        {
            title: 'Box Title',
            img: defaultImage,
            content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'
        },
    ]
}
const Layout3ImageTextBox = ({ section }) => {
    return (
        <div className='row m-0 bg-white p-3 brd-b-dashed'>

            <div className="col-md-12 py-3">
                <h3 className='fnt-w9 text-center txt-primary'>{section?.meta?.title || defaultData?.title}</h3>
            </div>

            {
                Array.isArray(section?.meta?.boxes) ?

                    section?.meta?.boxes.map(el =>
                        <div className="col-md-4 p-2">
                            <div className="col-md-12">
                                <h3 className='fnt-w9 text-center txt-primary'>{el?.title}</h3>
                            </div>

                            <div className='col-md-12 text-center'>
                                <img style={{ maxWidth: '100%' }} src={el?.img || defaultImage} alt="" />
                            </div>
                            <div className='col-md-12 pt-3 text-center'>
                                {parse(String(el?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
                            </div>
                        </div>
                    )
                    :
                    defaultData?.boxes.map(el =>
                        <div className="col-md-4 p-2">
                            <div className="col-md-12">
                                <h3 className='fnt-w9 text-center txt-secondary'>{el?.title}</h3>
                            </div>

                            <div className='col-md-12 text-center'>
                                <img style={{ maxWidth: '100%' }} src={el?.img} alt="" />
                            </div>
                            <div className='col-md-12 pt-3 text-center'>
                                {parse(String(el?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
                            </div>
                        </div>
                    )
            }





        </div >
    );
};

export default Layout3ImageTextBox;