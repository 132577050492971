import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isPendingAction, isRejectedAction, isFullfieldAction } from './slice.helper';
import Api from '../services/organisme.service';



export const getInfo = createAsyncThunk(
    "organisme/public-create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.getInfo(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)



const initialState = {
    loading: false,
    error: '',
    current_organisme: null
}

const OrganismeSlice = createSlice({
    name: "Organisme",
    initialState: initialState,
    reducers: {
        clear: (state) => {
            Object.assign(state, initialState)
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getInfo.pending, (state) => {
                state.loading = true;
                state.error = '';
                state.current_organisme = null;
            })
            .addCase(getInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.error = '';
                if (action.payload?.organisme) {
                    state.current_organisme = action.payload?.organisme;
                }
            })
            .addCase(getInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.current_organisme = null;
            })
            .addMatcher(isFullfieldAction, (state, action) => {
                state[action.meta.requestId] = 'fulfilled'
                state.loading = false;
                state.error = '';
            })
            .addMatcher(isPendingAction, (state, action) => {
                state[action.meta.requestId] = 'pending'
                state.loading = true;
                state.error = '';
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state[action.meta.requestId] = 'rejected'
                state.loading = false;
                state.error = action.payload;
            })



    }
})

const { reducer } = OrganismeSlice;
export const { clear } = OrganismeSlice.actions;
export default reducer;