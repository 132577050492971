import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import parse from 'html-react-parser';

const defaultData = {
    style_bg: '',
    style_title: '',
    questions: [
        { id: 0, title: 'Question ?', content: '<p>Réponse ....</p>' }
    ]
}

const FaqWidget = ({ section, loading }) => {

    return (
        <div className='row m-0 my-5 brd-b-dashed py-3'>
            <h1 className='text-center txt-primary'>FAQ</h1>
            <div className="col-md-4 text-center">
                <img src="/img/faq_bg.svg" width='80%' alt="" />
            </div>
            <div className="col-md-8 mb-5">
                <Accordion defaultActiveKey={0}>
                    {
                        Array.isArray(section?.meta_data?.questions) && section?.meta_data?.questions?.map(el =>
                            <Accordion.Item eventKey={el?.id}>
                                <Accordion.Header className='fnt-w9'><span className='fnt-w9'>{el?.title}</span></Accordion.Header>
                                <Accordion.Body>
                                    {parse(String(el?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    }
                </Accordion>
            </div>

        </div>
    );
};


export default FaqWidget; 