import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './features/main/Main';
import FormationListPublic from './features/formation/FormationListPublic';
import FormationDetailsPublic from './features/formation/FormationDetailsPublic';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/pub/formation' element={<FormationListPublic />} />
        <Route path='/pub/formation/:slug' element={<FormationDetailsPublic />} />
        <Route path='/*' element={<Main />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
