import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import defaultImage from '../../../assets/img/ic_photo.png'
import { api_file_url } from '../../../config/Params.constant';

const direction_var = { left: 'left', right: 'right' }
const defaultData = {
    title: 'Section Title',
    direction: 'left',
    img: defaultImage,
    content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'
}
const LayoutImageTextHoriz = ({ section }) => {

    return (
        <div className={`row m-0 p-0  ${section?.meta_data?.style_bg}`} >

            {/* <div className="col-md-12">
                <h2 className='fnt-w9 text-center txt-primary'>{section?.meta_data?.title}</h2>
            </div> */}
            {
                section?.meta_data?.direction == direction_var.left ?
                    <>
                        <div className={`${section?.meta_data?.style_col?.col_img} p-0 text-center`}>
                            <img style={{ maxWidth: '100%' }} className={`${section?.meta_data?.style_img}`} src={(section?.meta_data?.img ? `${api_file_url}${section?.meta_data?.img}` : defaultData.img)} alt="" />
                        </div>
                        <div className={`${section?.meta_data?.style_col?.col_content} p-4`}>
                            <div className="container px-4">
                                {section?.meta_data?.content ? parse(String(section?.meta_data?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '') : parse(String(defaultData?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
                                <div className="col-md-12 mt-3">
                                    <a target='_blank' href={`${section?.meta_data?.btn_link ? section?.meta_data?.btn_link : '#'}`} className={`btn px-5 py-2 ${section?.meta_data?.style_btn}`}>{section?.meta_data?.btn_text}</a>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className={`${section?.meta_data?.style_col?.col_content} p-4`}>
                            <div className="container px-4">
                                {section?.meta_data?.content ? parse(String(section?.meta_data?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '') : parse(String(defaultData?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
                                <div className="col-md-12 mt-3">
                                    <a target='_blank' href={`${section?.meta_data?.btn_link ? section?.meta_data?.btn_link : '#'}`} className={`btn px-5 py-2 ${section?.meta_data?.style_btn}`}>{section?.meta_data?.btn_text}</a>

                                </div>
                            </div>
                        </div>
                        <div className={`${section?.meta_data?.style_col?.col_img} p-0 text-center`}>
                            <img style={{ maxWidth: '100%' }} className={`${section?.meta_data?.style_img}`} src={(section?.meta_data?.img ? `${api_file_url}${section?.meta_data?.img}` : defaultData.img)} alt="" />
                        </div>
                    </>
            }

        </div>
    );
};

export default LayoutImageTextHoriz;