import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class OrganismeService {


    getInfo() {
        let token = storage.getToken();
        let organisme_token = window?.PUBLIC_TOKEN;
        return axios.get(`${api_url}/public/organisme/info`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

}

export default new OrganismeService()