import React, { useState, useEffect } from 'react';
// import logo from '../../assets/img/logo_.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, NavLink, Routes, Route } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import parse from 'html-react-parser';

const Footer = ({ user_routes, organisme }) => {
    return (
        <footer className='row m-0 bg-white p-3 '>
            <div className="container">
                <div className="row m-0 py-3">
                    <div className="col-md-4 text-center ">
                        <img src={'/img/logo/logo.png'} width="50%" alt="" />
                        <div className="col-md-12 px-0">
                            <a href={organisme?.link_facebook || "#"} target='_blank' className='menu-social-link me-2'><FontAwesomeIcon icon={['fab', 'facebook-square']} /></a>
                            <a href={organisme?.link_instagrame || "#"} target='_blank' className='menu-social-link me-2'><FontAwesomeIcon icon={['fab', 'instagram-square']} /></a>
                            <a href={organisme?.link_linkedin || "#"} target='_blank' className='menu-social-link'><FontAwesomeIcon icon={['fab', 'linkedin']} /></a>
                        </div>
                        <div className="col-md-12 px-5 pt-2">
                            <p className='fnt-sm fnt-w7 mb-2'>{organisme?.adresse ? parse(String(organisme?.adresse).replace(/(?:\r\n|\r|\n)/g, '<br />') || '') : "---"}</p>
                            <p className='fnt-sm fnt-w9 mb-0'>{organisme?.tel || "---"}</p>
                            <p className='fnt-sm fnt-w9 mb-0'>{organisme?.email || "---"}</p>
                        </div>
                    </div>

                    <div className="col-md-4  px-4 text-center border-side-grad">
                        <h4 className='fnt-w9'>Liens utiles</h4>
                        <div className='text-start ms-auto me-auto ps-3' style={{ width: 'fit-content' }}>
                            {
                                Array.isArray(user_routes) && user_routes.map(el =>
                                    <a className='menu-footer-links pb-1' href={el?.slug ? `/page/${el?.slug}` : "#"}>{el?.titre}</a>
                                )
                            }

                        </div>
                    </div>
                    <div className="col-md-4 text-center px-3">
                        <img src={'/img/logo_qualiopi.jpg'} width="50%" alt="Qualiopi processus certifié" />
                        <p className='fnt-sm ps-3 pt-2'>La certification qualité a été délivrée au titre des catégories d’actions suivantes: actions de formation, action permettant de faire valider les VAE, action de formation par apprentissage</p>
                    </div>
                </div>


                <div className="row m-0">
                    <div className="copyright_text text-center">
                        <p className="text-muted fnt-smlr" data-wow-duration="1s">© Copyright 2023 {organisme?.nom}.Powered by {organisme?.nom}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;