import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { createMessage } from '../../../slices/message.slice';

const ContactWidget = ({ section }) => {


    const validationSchema = Yup.object().shape({
        nom: Yup.string().required('Ce champ est obligatoire !'),
        prenom: Yup.string().required('Ce champ est obligatoire !'),
        email: Yup.string().email('email non valide !').required('Ce champ est obligatoire !'),
        tel: Yup.string().required('Ce champ est obligatoire !'),
        objet: Yup.string().required('Ce champ est obligatoire !'),
        message: Yup.string().required('Ce champ est obligatoire !'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, setValue, formState } = useForm(formOptions);
    const { errors } = formState;

    const { loading, error } = useSelector(state => state.message_store);
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [cand_success, set_cand_success] = useState(false)



    const onSubmit = (data) => {
        dispatch(createMessage(data)).unwrap().then(res => {
            if (res?.done) {
                set_cand_success(true)
            }
        })
    }


    return (
        <div className="container bg-white py-3 brd-b-dashed">

            {
                !cand_success ?

                    <div className='row m-0'>

                        <div className="col-md-6 text-center">
                            <h1 className='txt-primary fnt-w9 text-start'>Contactez-nous</h1>
                            <h1 className='txt-secondary fnt-w9 text-start'>pour plus <br />d'informations</h1>

                            <img src="/img/contact_bg.svg" width='70%' alt="" />
                        </div>
                        <div className="col-md-6">
                            <form onSubmit={handleSubmit(onSubmit)} className="row m-0 formation-resume-box-r">

                                <div className="col-md-12 form-group col-12">
                                    <label className="fnt-w9 fnt-sm">Nom</label>
                                    <input type="text" {...register("nom")} className={`form-control form-control-sm no-border-rad`} />
                                    <p className="text-danger">{errors.nom?.message}</p>
                                </div>
                                <div className="col-md-12 form-group col-12">
                                    <label className="fnt-w9 fnt-sm">Prénom</label>
                                    <input type="text" {...register("prenom")} className={`form-control form-control-sm no-border-rad`} />
                                    <p className="text-danger">{errors.prenom?.message}</p>
                                </div>
                                <div className="col-md-12 form-group col-12">
                                    <label className="fnt-w9 fnt-sm">E-mail</label>
                                    <input type="text" {...register("email")} className={`form-control form-control-sm no-border-rad`} />
                                    <p className="text-danger">{errors.email?.message}</p>
                                </div>
                                <div className="col-md-12 form-group col-12">
                                    <label className="fnt-w9 fnt-sm">Tél</label>
                                    <input type="text" {...register("tel")} className={`form-control form-control-sm no-border-rad`} />
                                    <p className="text-danger">{errors.tel?.message}</p>
                                </div>

                                <div className="col-md-12 form-group col-12">
                                    <label className="fnt-w9 fnt-sm">Objet</label>
                                    <input type="text" {...register("objet")} className={`form-control form-control-sm no-border-rad`} />
                                    <p className="text-danger">{errors.objet?.message}</p>
                                </div>

                                <div className="col-md-12 form-group col-12">
                                    <label className="fnt-w9 fnt-sm">Message</label>
                                    <textarea rows="3" type="text" {...register("message")} className={`form-control form-control-sm no-border-rad`} />
                                    <p className="text-danger">{errors.message?.message}</p>
                                </div>

                                <div className="col-md-12  py-3 text-end" >
                                    {loading ?
                                        <div className="col-md-12 p-4 text-ctr">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        :
                                        <button type="submit" className="btn app-btn-primary px-4" >ENVOYER <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                                    }
                                </div>

                            </form>
                        </div>

                    </div>
                    :
                    <div className="row m-0 p-3">
                        <div className="col-md-12 text-center formation-resume-box-r p-4">
                            <img src="/img/goal.svg" width="30%" alt="" />
                            <h1 className='txt-primary fnt-w9'>Votre message a été envoyé avec succès</h1>
                            <h5 className='txt-secondary fnt-w9'>Vous recevrez un e-mail lors du traitement de votre demande</h5>
                            <Link to={'/formation'}>
                                <button className='btn app-btn-primary px-3 py-2 my-3'>EXPLORER PLUS DE FORMATION</button>
                            </Link>
                        </div>
                    </div>
            }
        </div>
    );
};

export default ContactWidget;