import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class PageService {

    list(data) {
        let token = storage.getToken();
        let organisme_token = window?.PUBLIC_TOKEN;
        return axios.get(`${api_url}/public/page/list`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    view(slug) {
        let token = storage.getToken();
        let organisme_token = window?.PUBLIC_TOKEN;
        return axios.get(`${api_url}/public/page/view/${slug}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    viewByAttr(data) {
        let token = storage.getToken();
        let organisme_token = window?.PUBLIC_TOKEN;
        return axios.post(`${api_url}/public/page/view-attr`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

}

export default new PageService()