import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listPage } from '../../slices/page.slice';
import { getInfo } from '../../slices/organisme.slice';

import Header from '../../ui/menu/Header';
import Footer from '../../ui/menu/Footer';
import FormationDetails from '../formation/FormationDetails';
import FormationList from '../formation/FormationList';
import PageView from '../pages/PageView';
import Candidater from '../candidature/Candidater';
import PageHome from '../pages/PageHome';
import PageTarifs from '../pages/PageTarifs';
import OfflinePage from './Offline';
import CookiesBox from './CookiesBox';

const menu = [
    {
        label: 'L\'ecole',
        path: 'instiue',
        type: 'drop',
        name: 'ecole',
        sub_links: []
    },
    {
        label: 'Nos Formation',
        type: 'link',
        name: 'formations',
        path: 'formation',
        sub_links: []
    },
    {
        label: 'Alternance',
        path: 'alternance',
        type: 'drop',
        name: 'alternance',
        sub_links: []
    },
    {
        label: 'Contrat',
        path: '/contrat',
        type: 'drop',
        name: 'contrat'
    },
    // {
    //     label: 'Contrat de professionnalisation',
    //     path: '/contrat-professionnalisation',
    //     type: 'link',
    //     name: 'contrat-professionnalisation'
    // },
]
const Main = () => {
    const { loading, error, current_organisme } = useSelector(state => state.organisme_store);
    const dispatch = useDispatch();
    const [lst_sub_menu, set_lst_sub_menu] = useState([])
    const [lst_menu, set_lst_menu] = useState([])
    const [main_menu, set_main_menu] = useState([])
    const [footer_menu, set_footer_menu] = useState([])
    const [is_offline, set_is_offline] = useState(false)



    const load_menu_data = () => {
        dispatch(getInfo()).unwrap().then(res => {
            if (!res?.organisme?.is_publish) {
                set_is_offline(true)
            } else {
                if (Array.isArray(res?.pages?.rows)) {
                    set_lst_sub_menu(res?.pages?.rows)
                }

                if (Array.isArray(res?.menu?.rows)) {
                    set_lst_menu(res?.menu?.rows)
                }

            }
            // dispatch(listPage()).unwrap().then(res => {
            //     if (Array.isArray(res?.rows)) {
            //         set_lst_sub_menu(res?.rows)
            //     }
            // })
        }).catch(err => {
            set_is_offline(true)
        })

    }

    useEffect(() => {
        load_menu_data()
    }, [])

    useEffect(() => {
        if (Array.isArray(lst_sub_menu) && Array.isArray(lst_menu)) {
            // let tmp = menu.map(el => ({ ...el, sub_links: lst_sub_menu.filter(l => (l?.target_menu == el?.name && l?.is_menu == true)) }))
            let tmp = lst_menu.map(el => ({
                ...el, sub_links: lst_sub_menu.filter(l => (l?.target_menu == el?.id && l?.is_menu == true)).sort((a, b) => {
                    return a?.menu_order - b?.menu_order;
                })
            }))

            console.log(tmp)
            set_main_menu(tmp)
            set_footer_menu(lst_sub_menu.filter(el => el?.is_footer == true))

        }
    }, [lst_sub_menu, lst_menu])
    return (
        <>
            {is_offline ? <OfflinePage /> :
                <>
                    <Header user_routes={main_menu} organisme={current_organisme} />
                    <div className={"rw-cont bg-gray"}>
                        <Routes>
                            <Route index element={<PageHome />} />
                            <Route path='formation' element={<FormationList />} />
                            <Route path='formation/:slug' element={<FormationDetails />} />
                            <Route path='page/:slug' element={<PageView />} />
                            <Route path='candidater' element={<Candidater />} />
                            <Route path='tarifs' element={<PageTarifs />} />

                        </Routes>
                    </div>
                    <Footer user_routes={footer_menu} organisme={current_organisme} />
                    <CookiesBox />
                </>
            }

        </>
    );
};

export default Main;