import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'react-bootstrap/Table';
import parse from 'html-react-parser';

const defaultData = {
    columns: [{ key: `col_0`, title: 'column 0' }, { key: `col_1`, title: 'column 1' }],
    rows: [{ id: 0, col_0: 'column:0 row:0', col_1: 'column:1 row:0' }, { id: 1, col_0: 'column:0 row:1', col_1: 'column:1 row:1' },]
}
const LyTable = ({ section }) => {



    return (
        <div className='row m-0 p-5'>

            {Array.isArray(section?.meta_data?.columns) && Array.isArray(section?.meta_data?.rows) &&
                <Table striped bordered hover>
                    <thead>
                        <tr className='text-center'>
                            {
                                section?.meta_data?.columns.map(el => <th key={`th-${el?.key}`}>{el?.title}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            section?.meta_data?.rows.map(el =>
                                <tr>
                                    {section?.meta_data?.columns.map(c =>
                                        <td key={`itm-${el?.id}-${c?.key}`}>
                                            {el[c?.key] ? parse(String(el[c?.key]).replace(/(?:\r\n|\r|\n)/g, '<br />') || '') : ''}

                                        </td>
                                    )}
                                </tr>
                            )
                        }

                    </tbody>
                </Table>
            }

        </div>
    );
};

export default LyTable;