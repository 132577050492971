import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isPendingAction, isRejectedAction, isFullfieldAction } from './slice.helper';
import Api from '../services/message.service';



export const createMessage = createAsyncThunk(
    "Message/public-create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.create(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)



const initialState = {
    loading: false,
    error: '',
}

const MessageSlice = createSlice({
    name: "Message",
    initialState: initialState,
    reducers: {
        clear: (state) => {
            Object.assign(state, initialState)
        },
    },
    extraReducers: builder => {
        builder
            .addMatcher(isFullfieldAction, (state, action) => {
                state[action.meta.requestId] = 'fulfilled'
                state.loading = false;
                state.error = '';
            })
            .addMatcher(isPendingAction, (state, action) => {
                state[action.meta.requestId] = 'pending'
                state.loading = true;
                state.error = '';
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state[action.meta.requestId] = 'rejected'
                state.loading = false;
                state.error = action.payload;
            })



    }
})

const { reducer } = MessageSlice;
export const { clear } = MessageSlice.actions;
export default reducer;