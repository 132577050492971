import { configureStore } from '@reduxjs/toolkit';
import formationReducer from '../slices/formation.slice';
import pageReducer from '../slices/page.slice';
import candidatureReducer from '../slices/candidature.slice';
import messageReducer from '../slices/message.slice';
import organismeReducer from '../slices/organisme.slice';

export const store = configureStore({
  reducer: {
    formation_store: formationReducer,
    page_store: pageReducer,
    candidature_store: candidatureReducer,
    message_store: messageReducer,
    organisme_store: organismeReducer,
  },
});
